<template>
  <div class="app" style="background-color: #202A44">
    <!-- <v-app-bar color="#5d54a4"> -->
    <v-layout
      wrap
      justify-center
      class="hidden-sm-and-down "
      style="height: 80px"
    >
      <v-flex xs12 class="fixItem">
        <!-- <v-img src="../../assets/Images/hd.jpg"> -->
        <v-layout wrap justify-center fill-height>
          <v-flex xs12 md12 align-self-center>
            <v-card color="transparent" elevation="0">
              <v-layout wrap>
                <v-flex md5 lg5 pa-0>
                  <v-layout wrap justify-center>
                    <v-flex  @click="$router.push('/')" xs8 lg3 md3 align-self-center>
                      <v-img
                        style="cursor: pointer"
                        text
                        width="70px"
                        height="70px"
                        src="../assets/Images/rain_logo.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 md8 lg5 xl12 align-self-center style="color:#f7f7f7;font-family: OutfitRegular;">
                      <div class="contact-info">
                        <div class="contact-item">
                          <v-icon style="color:#f7f7f7" small>mdi-phone</v-icon>&nbsp;&nbsp; 7510346626
                        </div>
                        <div class="contact-item">
                          <v-icon style="color:#f7f7f7" small>mdi-email</v-icon>&nbsp;&nbsp; booking@rainvalley.co.in
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-layout wrap justify-end>
              
                  <v-flex align-self-center xs8 md12>
                    <v-layout wrap justify-center>
                      <v-flex
                        xs2
                        class="interSB"
                        style="text-align: justify"
                        text-left
                        v-for="(item, i) in category"
                        :key="i"
                      >
                        <router-link
                          :to="item.route"
                          :href="item.path"
                          style="text-decoration: none"
                        >
                          <span
                            style="
                              cursor: pointer;
                              text-decoration: none;
                              color: white;
                           font-family: OutfitRegular;
                            "
                            class="pack28 text1"
                            >{{ item.title }}</span
                          >
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout> -->
                <v-layout wrap justify-end>
                  <!-- <v-flex xs1 pt-1><a class="interSB" style="text-decoration:none; color:white" href="/#about">About</a></v-flex> -->
                  <v-flex align-self-center xs8 md12>
                    <v-layout wrap justify-center>
                      <v-flex
                        xs12
                       
                        style="text-align: justify"
                        text-center
                        
                      >



                      <v-btn
                      style="
                      cursor: pointer;
                      text-decoration: none;
                      color: white; background-color:#202A44;
                   font-family: OutfitRegular;
                    "
                v-for="(item, index) in category"
                :key="index"
                @click="scrollToRoute(item.route)"
                :to="item.route"
                :class="setActiveButton(item.route)"
                variant="text"
                elevation="0"
                class="hdr nav-btn"
              >
                {{ item.title }}
              </v-btn>


                        <!-- <router-link
                          :to="item.route"
                          :href="item.path"
                          style="text-decoration: none"
                        >
                          <span
                            style="
                              cursor: pointer;
                              text-decoration: none;
                              color: white;
                           font-family: OutfitRegular;
                            "
                            class="pack28 text1"
                            >{{ item.title }}</span
                          >
                        </router-link> -->
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
             
             
             
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      
      </v-flex>
    </v-layout>

    <!-- For Small screen -->

    <v-layout class="hidden-md-and-up gradtop">
      <v-flex xs12>
        <!-- <v-img src="../../assets/Images/hd.jpg"> -->
        <v-layout wrap>
          <v-flex xs11 md10 align-self-start>
            <!-- <router-link to="/" style="text-decoration: none"
                  > -->
                  <v-flex sm12 pa-0>
                    <v-layout wrap justify-center>
                      <v-flex  sm2 pl-sm-3 @click="$router.push('/')"  align-self-center>
                        <v-img
                          style="cursor: pointer"
                          text
                          width="70px"
                          height="70px"
                          src="../assets/Images/rain_logo.png"
                        ></v-img>
                      </v-flex>
                      <v-flex   align-self-center style="color:#f7f7f7;font-family: OutfitRegular;">
                        <div class="contact-info">
                          <div class="contact-item">
                            <v-icon style="color:#f7f7f7" small>mdi-phone</v-icon>&nbsp;&nbsp; 7510346626
                          </div>
                          <div class="contact-item">
                            <v-icon style="color:#f7f7f7" small>mdi-email</v-icon>&nbsp;&nbsp; booking@rainvalley.co.in
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  
            <!-- </router-link
                > -->
          </v-flex>

        

          <v-flex xs1 pt-5>
            <v-app-bar-nav-icon @click="nav = true">
              <v-icon color="white">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
          </v-flex>
        </v-layout>
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      left
      v-model="nav"
      app
      fixed
      temporary
      style="background-color: #f7f7f7"
    >
      <v-layout wrap justify-center>
        <v-flex
          style="font-family: intersemib; font-size: 17px; text-align: justify"
        >
          <v-list dense nav>
            <v-list-item
              v-for="item in category"
              :key="item.title"
              link
              :to="item.route"
            >
              <v-list-item-icon>
                <v-img height="20px" contain :src="item.src"></v-img>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  ><span style="color: black" class="fnt1">{{
                    item.title
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: false,
      items: [
        { title: "Home", route: "/", type: "link" },
        
        { title: "Rooms", route: "/rooms", type: "link" },
        { title: "About Us", route: "/aboutus", type: "link"},
        // { title: "Gallery", route: "/GalleryPage", type: "link" },
        { title: "Contact Us", route: "/contactus" },
      ],
      category: [
        { title: "Home", route: "/", type: "link" },
        
        { title: "Rooms", route: "/rooms", type: "link" },
        { title: "About Us", route: "/aboutus", type: "link", path: "#about"},
        // { title: "Gallery", route: "/GalleryPage", type: "link" },
        { title: "Contact Us", route: "/contactus" },
      ],
    };
  },
  computed: {
      activeRoute() {
        return this.$route.path;
      },
    },
    methods: {
  setActiveButton(route) {
    return route === this.activeRoute ? "active-button active-text" : "active-button";
  },
  scrollToRoute(route) {
    this.activeRoute = route;
    this.scroll();
  },
  scroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
}
};
</script>
<style>


.router-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.hdr {
  font-family: archireg;
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px; /* Adjusts the space between nav items */
}

.nav-btn:hover {
  background-color: white !important; /* Background color on hover */
  color: black !important; /* Text color on hover */
}
</style>