import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueElementLoading from "vue-element-loading";
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.component("VueElementLoading", VueElementLoading);    

Vue.config.productionTip = false


// axios.defaults.baseURL = 'http://192.168.52.121:5400';
// Vue.prototype.baseURL = "http://192.168.52.121:5400"
// Vue.prototype.mediaURLnew = "http://192.168.52.121:5400/file/get/"
// Vue.prototype.mediaURL = "http:192.168.52.121:5400/u/"


// axios.defaults.baseURL = 'http://192.168.52.121:5400';
// Vue.prototype.baseURL = "http://192.168.52.121:5400"
// Vue.prototype.mediaURL = "http://192.168.52.121:5400/u/"
// Vue.prototype.mediaURLnew = "http://192.168.52.121:5400/wp/"

axios.defaults.baseURL = 'https://api.rainvalley.co.in/';
Vue.prototype.baseURL = "https://api.rainvalley.co.in/"
Vue.prototype.mediaURLnew = "https://api.rainvalley.co.in/file/get/"

new Vue({   
  vuetify,
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
