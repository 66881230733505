<template>
  <div style="background-color: rgba(29, 41, 46, 1)">
    <v-layout wrap justify-center class="LexendRegular">
      <v-flex xs8 md4 sm4 lg3>
        <v-layout wrap class="add" justify-center>
          <v-flex xs4 sm4 pt-lg-6 pt-md-3 pt-sm-8 class="px-0 py-0 pb-lg-5">
            <v-img  src="../assets/Images/rain_logo.png"></v-img>
          </v-flex>
        
        </v-layout>
      </v-flex>
      <v-flex xs11 sm4 md4 pt-0 align-self-center >
        <v-layout wrap justify-center pt-2 >
          <v-flex xs1  align-self-center class="pt-lg-3">
            <v-icon color="white">mdi-map-marker-radius</v-icon>
          </v-flex>
            <v-flex xs11 lg11 class="px-0 py-0 pl-sm-3 pl-md-2 pl-2 pl-lg-0 pt-lg-3" align-self-center>
              <span style="color: white;font-family: OutfitRegular;">
                Kottayam Kumily Road, Kuttikanam,
              </span>
            </v-flex>

            <v-flex sm10  lg10 class="px-0 py-0 pl-sm-3 pl-md-2 pl-10 pl-lg-0" >
              <span style="color: white;font-family: OutfitRegular;">
                Peermade, Idukki - 685531, Kerala
              </span>
            </v-flex>

            <!-- <v-flex  lg10 class="px-0 py-0 pl-sm-3 pl-md-2 pl-2 pl-lg-0" >
              <span style="color: white;font-family: OutfitRegular;">
                Idukki - 685531
              </span>
            </v-flex>

            <v-flex  lg10 class="px-0 py-0 pl-sm-3 pl-md-2 pl-2 pl-lg-0" >
              <span style="color: white;font-family: OutfitRegular;">
                Kerala
              </span>
            </v-flex> -->
          </v-layout>

            <v-layout wrap justify-center pt-2>
          <v-flex xs1 align-self-center>
            <v-icon color="white">mdi-email</v-icon>
          </v-flex>
            <v-flex xs11 lg11 class="px-0 py-0 pl-sm-3 pl-md-2 pl-2 pl-lg-0" align-self-center>
              <span style="color: white;font-family: OutfitRegular;">
                booking@rainvalley.co.in
              </span>
            </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-2 pb-2>
          <v-flex xs1 lg1 align-self-center>
            <v-icon color="white">mdi-phone</v-icon>
          </v-flex>
            <v-flex xs11 lg11 class="px-0 py-0 pl-sm-3 pl-md-2 pl-2 pl-lg-0 pb-lg-3" align-self-center>
              <span style="color: white;font-family: OutfitRegular;">
                7510346626
              </span>
            </v-flex>
            </v-layout>


        
      </v-flex>
      <v-flex xs12 sm3 md4>
        <v-layout wrap justify-center fill-height>
         
            <v-flex align-self-center xs8 class="pt-lg-3"> 
              <v-btn text @click="home()" color="white">
                <span style="font-family: LexendRegular;">
                  Home

                </span>
                </v-btn>
            </v-flex>
            <v-flex align-self-center xs8 class=" py-0">
              <v-btn style="font-family: OutfitRegular;" text @click="rooms()" color="white">Rooms</v-btn>
            </v-flex>
            <v-flex align-self-center xs8 class=" py-0">
              <v-btn text @click="aboutus()" color="white" style="font-family: OutfitRegular;">About Us</v-btn>
            </v-flex>
            <v-flex align-self-center xs8 class=" py-0 pb-lg-3">
              <v-btn text @click="contactus()" color="white" style="font-family: OutfitRegular;">Contact Us</v-btn>
            </v-flex>
     
        </v-layout>
      </v-flex>
    </v-layout>



    <v-layout pa-4 justify="center" class="LexendRegular" style="background-color: rgb(47, 64, 71)">
      <v-flex xs12 class="text-center LexendRegular">
        <span style="color: white;font-family: OutfitRegular;">
          © Rain Valley - All rights reserved
          <span>|</span> Designed & Developed by
          <span style="color: #f4c671;font-family: OutfitRegular;">Leopard Tech Labs Pvt Ltd</span>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  methods: {
    navigate() {
      // Handle the navigation button click
    },
    home() {
      this.$router.push('/')
    },
    gallery() {
      // Handle the gallery button click
    },
    rooms() {
      this.$router.push('/rooms')
    },
    aboutus() {
      this.$router.push('/aboutus')
    },
    contactus() {
      this.$router.push('/contactus')
    },
    blogs() {
      // Handle the blogs button click
    },
  },
};
</script>

<style scoped>
.subbbbb {
  font-family: LexendRegular;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-transform: lowercase;
}

.subtwo {
  color: #cfc7c7;
  font-size: 16px;
  font-family: opensans;
  font-weight: 400;
}
.ftr {
  font-size: 15px;
  font-weight: 400;
  font-family: LexendRegular;
}
.add {
  color: #cfc7c7;
  font-family: LexendRegular;
}
</style>
